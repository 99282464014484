import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2013729323/src/git-codecommit.ap-southeast-2.amazonaws.com/v1/repos/ai-assist-ui/node_modules/next/dist/client/components/render-from-template-context.js");
